<template>
  <div class="NosotrosView">
    <div
      width="100%"
      class="mb24"
    >
      <v-btn
        class="primary"
        to="/"
        depressed
      >
        <v-icon>arrow_back</v-icon> Regresar
      </v-btn>
    </div>
    <v-card
      outlined
      class="mb24"
    >
      <v-card-title v-text="'Misión'" />
      <v-card-text v-html="configuracion.mision_con" />
    </v-card>
    <v-card
      outlined
      class="mb24"
    >
      <v-card-title v-text="'Visión'" />
      <v-card-text v-html="configuracion.vision_con" />
    </v-card>
    <v-card
      outlined
      class="mb24"
    >
      <v-card-title v-text="'Aviso de Privacidad'" />
      <v-card-text v-html="configuracion.aviso_privacidad_con" />
    </v-card>
    <v-card
      outlined
      class="mb24"
    >
      <v-card-title v-text="'Términos y Condiciones'" />
      <v-card-text v-html="configuracion.terminos_condiciones_con" />
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "NosotrosView",
  mixins: [standard, forms],
  data() {
    return {
      configuracion: {
        mision_con: "",
        vision_con: "",
        aviso_privacidad_con: "",
        terminos_condiciones_con: "",
      },
    };
  },
  methods: {},
  created() {
    this._getThings("nosotros", "configuracion");
  },
};
</script>